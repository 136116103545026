<template>
  <div class="pageBox">
    <div class="content">
      <h3 class="title">
        <p>问题申诉记录</p>
      </h3>
      <div class="applicationBox">
        <el-table
          class="tableBox"
          stripe
          :data="tableData"
          style="width: 100%;min-height:468px;">
          <el-table-column align="center" type="index" label="序号" width="100" ></el-table-column>
          <el-table-column align="center" label="问题类型">
            <template slot-scope="scope">
              <div v-for="(item,index) in scope.row.type" :key="index">
                <p>{{item}}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="申诉厂商">
            <template slot-scope="scope" >
              <div v-for="(item,index) in scope.row.memberName" :key="index">
                <p>{{item}}</p>
              </div>  
            </template>
          </el-table-column>
          <el-table-column align="center" label="申诉时间">
            <template slot-scope="scope" >
              <p>{{scope.row.createTime | parseTime()}}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="state" label="状态">
            <template slot-scope="scope">
              <p>{{ scope.row.state | appealResult() }}</p>     
            </template>
          </el-table-column>
          <el-table-column align="center" prop="dealTime" label="处理时间">
            <template slot-scope="scope" >
              <p v-if="scope.row.dealTime==null">/</p>
              <p v-else>{{scope.row.dealTime | parseTime()}}</p>
            </template>
          </el-table-column>
          <el-table-column align="center"  label="详情">
            <template slot-scope="scope">
              <a class="blue" @click="onLook(scope.row.id)">查看</a>
            </template>  
          </el-table-column>
        </el-table>

        <div class="margin-t30 text-right">
          <el-pagination
            class="paginationBox"
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page.sync="page"
            :page-sizes="[10,20,50,100]"
            :page-size="perPage"
            :total="total"
            layout="total, prev, pager, next, sizes"
          ></el-pagination>
        </div>
      </div>
    </div>
    <ICP></ICP>
    <AppealDetails ref="componentView"></AppealDetails>
    
  </div> 
</template>

<script>
import { singleGoldIdentificationSever } from "@/common/api-config";
import AppealDetails from "./template/appealDetails.vue";
import ICP from "@/components/ICP";
export default {
  components: { AppealDetails, ICP},
  data(){
    return{
      page: 1,
      perPage: 10,
      total: 0,
      tableData: [], //申诉记录list
    }
  },
  created(){
    this.queryList()
  },
  methods:{
    //每页多少条
    sizeChange(val) {
      this.perPage = val;
      this.queryList();
    },
    //翻页
    currentChange(val) {
      this.page = val;
      this.queryList();
    },
    //获取应用版本列表
    queryList(){
      singleGoldIdentificationSever
        .queryList({
          page: this.page,
          perPage: this.perPage,
        }).then(res => {
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    onLook(id) {
        this.$nextTick(()=>{
          this.$refs["componentView"].show(id);
        })
      },
  }
}
</script>

<style scoped lang="scss">
.pageBox{
  padding-top: 120px;
  border-radius: 5px;
  min-height: calc(100% - 94px); //减底部(20px+ICP高度+20px)
  .content{
    padding: 20px 50px;
    background: #fff;
    .title {
      position: relative;
      display: flex;
      align-items: center;
      height: 100px;
      padding-left: 49px;
      font-size: 24px; 
      line-height: 100%;
      color: rgba(201, 193, 153, 1);
      background: url(/assets/img/console/titleBg.jpg) no-repeat #28252c;
      background-position: 70% 0;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 33px;
        width: 5px;
        height: 24px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: #ded094;
      }
    }
    .applicationBox{
      padding: 30px;
      .tableBox{
        margin-top: 30px;
        .blue{
          cursor: pointer;
        }
      }
    }
  }
}
</style>