<template>
   <el-dialog
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="false"
    @close="dialogVisible = false"
    :close-on-click-modal="false"
    class="applicationInfoBox"
    :modal-append-to-body="false"
    center>
    <h3 slot="title" class="title fontSize20 margin-t20">
      查看申诉记录
    </h3>
    <el-form v-if="dialogVisible" class="form-box" :model="formData" ref="refForm" label-width="100px">
      <el-form-item class="form-item" label="问题类型:" v-if="formData.type">
        <span v-for="(item,index) in formData.type" :key="item">
          <span v-if="index">、</span>
          <span >{{item}}</span> 
        </span>
      </el-form-item>
      <el-form-item class="form-item describe-box" label="问题描述:"  v-if="formData.appealPicUrl">
        <p class="describe" v-html="textareaFormat(formData.appealDesc)"></p>
        <div>
          <el-image class="screenshot" v-for="item in formData.appealPicUrl" :key="item" :src="item"  :preview-src-list="formData.appealPicUrl"></el-image>
        </div>
      </el-form-item>
      <el-form-item class="form-item manage-box" label="处理结果：">
        <div v-if="formData.state == 2">
            您反馈的问题属于无效申诉，官方工作人员不做处理。<br>
            若您有其他问题，请通过微信公众号“移动智能终端生态联盟”反馈
        </div>
        <div v-else class="manage">{{formData.dealDesc}}</div>
      </el-form-item>
    </el-form>
    <div class="text-center margin-t30  margin-b10">
        <el-button size="medium" type="info" @click="dialogVisible = false">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { singleGoldIdentificationSever } from "@/common/api-config";
export default {
  data() {
    return {
      //显示dialog
      dialogVisible: false,
      //数据
      formData: {},
    };
  },
  methods:{
    //获取申诉详情
    queryDetail(id) {
      singleGoldIdentificationSever.queryDetail({id}).then(res => {
        if (res['code'] == 0) {
          this.formData=res['data'];
        } 
      });
    },
    show(id) {
      this.dialogVisible = true;
      if (id) this.queryDetail(id);
    },
    textareaFormat(text) {
      return text.replace(/\n|\r\n/g, '<br/>')
    },
  }
}
</script>

<style scoped lang="scss">
.applicationInfoBox{
  /deep/ .el-dialog {
    border-radius: 10px;
  }
  .title {
    position: relative;
    font-size: 18px;
    color: rgba(18, 18, 18, 1);
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      width: 73px;
      height: 6px;
      background: rgba(222, 208, 148, 1);
      border-radius: 3px;
      margin-bottom: 10px;
    }
  }

  .form-box{
    padding-right: 10px;
    .form-item {
      margin-bottom: 0;
    }
    .describe-box{
      .describe{
        padding-top: 8px;
        padding-bottom: 8px;
        line-height: 24px;
      }
      .screenshot {
        display: inline-block;
        margin-right:20px;
        width: 80px;
        height: 120px;
      }
    }
    .manage-box{
      .manage {
        padding-top: 8px;
        line-height: 24px;
      }
    }
  }
}
</style>